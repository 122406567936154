import React from "react"
import test_logo from './../../images/sub.testimonials.gif'
import crest from './../../images/cz-red.gif'

const Testimonials= function() {
    
    return (
        <div className="Home">
            <br></br>
            <div className="body">
                <div className="title">
                    <img src={crest} className="img"/>
                    <span className="title_words">Testimonials from My Students</span>
                </div><br/>
                <div className="left"> 
                "Silvie's superpower is that she intuitively understands how language 
                is learned. She combines natural conversation with relevant points 
                of grammar in a fun and student-focused approach to instruction. Her 
                knowledge, energy, patience and professionalism are impressive. 
                Silvie is a talented teacher and I always look forward to my lessons!"
                <br/><br/>
                - Matt Pollitz, Small Business Owner
                <br/>
                Seattle, WA 
                <br/><br/>
                "Silvie is a great teacher.  She understands the needs of her students 
                and is very helpful with questions that arise concerning grammar and 
                pronunciation. Her lessons are designed personally with the capabilities 
                and needs of each student in mind. I am blessed to have Silvie as my teacher."
                <br/><br/>
                - Diane Hoffman, Retired Elementary Teacher
                <br/>Seattle, WA
                <br/><br/>
                "As an American-born daughter of first-generation Czechs, I was exposed 
                to the Czech language but never learned it. It&#39;s long been a goal of
                mine to be able to speak my extended family&#39;s native tongue. However,
                despite attempting a variety of learning modalities over the years 
                (classes, tutoring, self-teaching), I just couldn&#39;t seem to stick 
                with it. Finally, I found Silvie – someone who has come to feel like 
                a true partner in this learning journey I&#39;ve chosen. Silvie is 
                an exceptional teacher. She is consistent, smart, focused, personable 
                and clearly takes her educator role seriously. Because of these qualities,
                I have not once wavered in my desire and commitment to learn Czech, 
                and I am so gratified by the progress I&#39;ve been making working 
                with her remotely for a year now. I could not recommend Silvie more highly. 
                It&#39;s clear to me that a really good teacher makes all the difference!"
                <br/><br/>
                - Liz M. Braun, Ph.D., Non-profit Human Services Executive
                <br/><br/>
                "I highly recommend Silvie as a Czech language instructor. Silvie is a 
                native speaker of Czech and fluent in English. She is able to explain 
                complicated grammar in an easy to understand manner. She is very good 
                with working on pronunciation. She is patient and listens and explains 
                how to correct improper pronunciation. She is always prepared for the 
                lesson and moves the lesson forward at your individual pace. She works 
                to build conversational skills of listening and speaking while being sure 
                the proper grammar is learned. She is an excellent instructor."
                <br/><br/>
                - Scott Timmons, Judge
                <br/>Olympia, WA
                <br/><br/>
                "I&#39;ve been a student of Silvie for over four years. Her expertise 
                in teaching me to speak one of the hardest languages on the planet, 
                is only surpassed by her in-depth knowledge of grammar in both English 
                and Czech. However, it&#39;s her undying patience, her listening skills, 
                and above all the agility to laugh that makes my learning process 
                fun…and enduring. I cannot recommend her enough as a teacher. I guarantee 
                you will be pleased if you decide to start this journey with her as your 
                instructor."
                <br/><br/>
                - Liz Riley, Retired Program Operation Specialist (Grants)
                <br/><br/>
                "Silvie is a patient teacher with an excellent sense of humor – which one 
                needs in teaching (or learning) a foreign language. She does a wonderful 
                job of catering to a student's needs and creating lessons that improve 
                the student in those areas."
                <br/><br/>
                - Anne-Marie Davidson, Research Analyst
                <br/><br/>
                "Silvie is organized, responsible, and extremely intelligent. She knows 
                the Czech language inside-out - the colloquial spoken as well as the more 
                formal written. What is perhaps more important, though, is the reality 
                that she understands how to most effectively pass that knowledge on to 
                her students. Whether you’re interested in becoming fluent or just looking 
                for a personalized "crash course" before traveling to the Czech Republic, 
                Silvie is the person to go to."
                <br/><br/>
                - Philip Valenta, IT Systems Specialist & Part-Time Faculty, Seattle 
                Central Community College
                <br/><br/>
                "I have been taking private Czech language instruction from Silvie Opatrna 
                for 2 months and I am very pleased with the results so far. From the 
                beginning, I was impressed with the care she took in understanding my 
                specific goals and objectives. She even took time to survey my learning 
                \style to find out how I learn best. Each lesson is well-planned and focuses 
                on building speaking and listening skills. Silvie is truly talented, has a 
                personable demeanor and really enjoys teaching. I feel that I am receiving a 
                high level of quality with Silvie and can easily recommend her as a Czech 
                language teacher."
                <br/><br/>
                - Nancy Smalley, Registered Massage Therapist
                <br/><br/>
                "Silvie is an excellent teacher. Not only is she attentive to the student’s needs, 
                but she is incredibly patient. She is one of friendliest persons I’ve ever met and 
                as such, I felt immediately relaxed around her. Silvie creates an atmosphere that 
                is fun, focused, and flexible. I highly recommend her especially if you’re a 
                beginner like I am."
                <br/><br/>
                - Blake Dodson, Software Tester
                <br/><br/>
                "Silvie is an exceptional teacher. She has a great motivating nature and is a pleasure 
                to be around. I highly recommend her."
                <br/><br/>
                - Dayla Ferguson, English Teacher
                <br/><br/>
                "I am learning to sing a seven song cycle by Dvorak now. Silvie is helping me with my 
                diction. She explained the difference between using the soft pallet versus using the 
                hard pallet and the correct tongue position and structures of the mouth involved in 
                producing the sounds. Also, she translated the songs word by word and explained the 
                meaning of the lyrics very well. She really has a good sense of poetry. I strongly 
                recommend her."
                <br/><br/>
                - Joo-Young Jung, DMA Voice Student
                <br/><br/>
                "Silvie is a great teacher and made our lessons both thorough and fun. While she 
                tailored my lessons to my specific needs and the purpose of my trip to the Czech 
                Republic, she stressed grammar fundamentals. Learning with her made me want to 
                continue to learn more advanced grammar as well as improve my speaking skills."
                <br/><br/>
                - Charles Reidy, Photographer
                <br/><br/>
                "I am an American living in Czech for a second year. In between this year and the 
                last, I returned home to Seattle for one year. During this time, I studied with 
                Silvie once a week for 2 hours. Now, back in Nachod, friends and colleagues simply 
                cannot believe the progress I've made with my Czech. Quite frankly neither can I. 
                They are especially in disbelief of my accent; friends and strangers often smile 
                and remark "hezky cesky". Silvie is quite strict about accent, and so are the rest 
                of Czech people. Sometimes it really is the difference between being understood or 
                not. At the moment here in Nachod, I haven't got a Czech teacher. I often wish I had 
                a little pocket Silvie to help take me to the next level. Her lessons are very 
                organized yet leave plenty of room for what the student wants and needs on any 
                particular day. I teach English here in Nachod, and Silvie's lessons have helped me 
                to become an even better teacher of English."
                <br/><br/>
                - Leslie Ramier, Business Owner and English Teacher
                </div>
            </div>
            <br/><br/>
        </div>
    )

}

export default Testimonials